import { Route, Routes } from 'react-router-dom'
import { Layouts, Pages } from './constants'

const ROLES = {
  User: 2001,
  Editor: 1984,
  Admin: 1
}

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Layouts.RouteLayout />}>
        {/* public routes */}
        <Route path="login" element={<Pages.SignInPage />} />
        <Route path="unauthorized" element={<Pages.UnauthorizedPage />} />

        {/* we want to protect these routes */}
        <Route element={<Layouts.PersistLoginLayout />}>
          <Route element={<Layouts.RequireAuthLayout allowedRoles={[ROLES.Admin]} />}>
            <Route path="/" element={<Pages.DashboardPage />} />
            <Route path="/container/*" element={<Pages.ContainerPage />} />
          </Route>
        </Route>

        {/* catch all */}
        <Route path="*" element={<Pages.MissingPage />} />
      </Route>
    </Routes>
  )
}
export default App
