import DataTable from 'react-data-table-component'
import _ from 'lodash'

const TableComponent = (props) => {
  return (
    <DataTable
      className="h-[60vh]"
      customStyles={props?.customStyles}
      columns={props?.columns}
      data={_.sortBy(props?.data, ['Id'])}
      defaultSortFieldId
      progressPending={props?.pending}
      highlightOnHover
      responsive={true}
      fixedHeader
      striped={true}
      {...props}
    />
  )
}

export default TableComponent
