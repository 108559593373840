import { useState, useEffect } from 'react'
import { Switch } from '@headlessui/react'

const SwitchComponent = (props) => {
  const [enabled, setEnabled] = useState(false)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setEnabled(props.data)
    }, 500)

    return () => clearTimeout(timeout)
  }, [props.data])

  function handleOnChange(value) {
    setEnabled(value)
    props.onChange(value)
  }

  return (
    <Switch
      checked={enabled}
      onChange={handleOnChange}
      className={`${
        enabled ? 'bg-green-500' : 'bg-gray-200'
      } relative inline-flex h-6 w-11 items-center rounded-full`}
    >
      <span className="sr-only">Enable notifications</span>
      <span
        className={`${
          enabled ? 'translate-x-6' : 'translate-x-1'
        } inline-block h-4 w-4 transform rounded-full bg-white transition`}
      />
    </Switch>
  )
}

export default SwitchComponent
