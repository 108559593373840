import React from 'react'
import ReactDOM from 'react-dom/client'
import { disableReactDevTools } from '@fvilers/disable-react-devtools'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import App from './App'
import { Layouts, Providers } from './constants'
import './assets/css/index.css'

if (process.env.NODE_ENV === 'production') {
  disableReactDevTools()
}

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <BrowserRouter>
      <Providers.AuthProvider>
        <Layouts.CenterScreenLayout>
          <Routes>
            <Route path="/*" element={<App />} />
          </Routes>
        </Layouts.CenterScreenLayout>
      </Providers.AuthProvider>
    </BrowserRouter>
  </React.StrictMode>
)
