import { useNavigate } from 'react-router-dom'
import { Layouts } from '../constants'

const UnauthorizedPage = () => {
  const navigate = useNavigate()

  const goBack = () => navigate('/login')

  return (
    <Layouts.SignInLayout>
      <div className="flex flex-1 flex-col items-center justify-center space-y-3 py-12 px-4 sm:px-6 lg:px-12 xl:px-12 rounded-xl bg-secondary">
        <h1 className="text-5xl">Unauthorized</h1>
        <h2 className="text-xl">You do not have access to the requested page.</h2>
        <div>
          <button
            onClick={goBack}
            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:primary"
          >
            Go back
          </button>
        </div>
      </div>
    </Layouts.SignInLayout>
  )
}

export default UnauthorizedPage
