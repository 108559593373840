import { createContext, useContext, useState } from 'react'
import { node } from 'prop-types'

const DashboardContext = createContext({})

export function useDashboardContext() {
  return useContext(DashboardContext)
}

const DashboardProvider = ({ children }) => {
  const [data, setData] = useState(null)

  function handleUpdateData(value) {
    setData((prevData) => ({
      ...prevData,
      value
    }))
  }

  const store = {
    data,
    action: {
      handleUpdateData
    }
  }

  return <DashboardContext.Provider value={store}>{children}</DashboardContext.Provider>
}

DashboardProvider.propTypes = {
  children: node.isRequired
}

export default DashboardProvider
