import { Routes, Route } from 'react-router-dom'
import { Pages, Providers } from '../../constants'

const ContainerPages = () => {
  return (
    <Providers.ContainerProvider>
      <Routes>
        <Route path="/dashboard/*" element={<Pages.DashboardPage />} />
        {/* <Route path="/template/*" element={<Pages.TemplatePage />} /> */}
        <Route path="/usage-log/*" element={<Pages.UsageLogPage />} />
        <Route path="/coupon/*" element={<Pages.CouponPage />} />
        <Route path="*" element={<Pages.MissingPage />} />
      </Routes>
    </Providers.ContainerProvider>
  )
}

export default ContainerPages
