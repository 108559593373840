import { useState, useEffect, useContext } from 'react'
import dayjs from 'dayjs'
import _ from 'lodash'
import { Components, Layouts, Images, Contexts } from '../../constants'
import { PlusCircleIcon, PencilSquareIcon } from '@heroicons/react/24/outline'
import { MinusCircleIcon } from '@heroicons/react/24/solid'
import axios from '../../services/axios'

const customStyles = {
  rows: {},
  headCells: {
    style: {
      backgroundColor: '#D9D9D9',
      color: '#2D3A30',
      fontSize: '16px',
      fontWeight: 800
    }
  },
  cells: {}
}

const CouponPage = (props) => {
  const { auth } = useContext(Contexts.AuthContext)
  const [data, setData] = useState([])
  const [totalRows, setTotalRows] = useState(0)
  const [perPage, setPerPage] = useState(50)
  const [dataModal, setDataModal] = useState([])
  const [modalAction, setModalAction] = useState('')
  const [columns, setColumns] = useState([])
  const [pending, setPending] = useState(true)
  const [openModal, setOpenModal] = useState(false)
  const [refreshStats, setRefreshStats] = useState(false)

  useEffect(() => {
    const timeout = setTimeout(() => {
      handleFetchData(1)
      setColumns([
        {
          name: 'ลำดับ',
          selector: (row) => row.Id,
          center: true,
          sortable: true,
          ignoreRowClick: true,
          maxWidth: '100px'
        },
        {
          name: 'รหัสคูปอง',
          selector: (row) => row.CupOnKey,
          ignoreRowClick: true,
          maxWidth: '130px'
        },
        {
          name: 'รายละเอียด',
          selector: (row) => row.CupOnDescription,
          ignoreRowClick: true
        },
        {
          name: 'จำนวนที่ใช้ได้',
          selector: (row) => row.CupOnCount,
          ignoreRowClick: true,
          center: true,
          maxWidth: '140px'
        },
        {
          name: 'ราคา',
          selector: (row) => row.CupOnPrice,
          sortable: true,
          center: true,
          maxWidth: '100px'
        },
        {
          name: 'วันที่สร้าง',
          selector: (row) => dayjs(row.CreateDate).format('DD/MM/YYYY HH:mm'),
          sortable: true,
          ignoreRowClick: true,
          maxWidth: '150px'
        },
        {
          name: 'วันที่แก้ไขล่าสุด',
          selector: (row) => dayjs(row.UpdateDate).format('DD/MM/YYYY HH:mm'),
          sortable: true,
          ignoreRowClick: true,
          maxWidth: '164px'
        },
        {
          name: 'แก้ไข',
          ignoreRowClick: true,
          button: true,
          maxWidth: '50px',
          cell: (row) => (
            <PencilSquareIcon
              onClick={() => handleModal(row)}
              className="h-[24px] text-primary cursor-pointer"
              aria-hidden="true"
            />
          )
        },
        {
          name: 'ลบ',
          ignoreRowClick: true,
          button: true,
          maxWidth: '50px',
          cell: (row) => (
            <MinusCircleIcon
              onClick={() => handleRemoveData(row)}
              className="h-[24px] text-red-500 cursor-pointer"
              aria-hidden="true"
            />
          )
        },
        // {
        //   name: 'ไม่จำกัด',
        //   ignoreRowClick: true,
        //   button: true,
        //   maxWidth: '50px',
        //   cell: (row) => (
        //     <Components.SwitchComponent
        //       onChange={() => handleUpdateData(row, 'unlimited')}
        //       data={row.IsUnlimited}
        //     />
        //   )
        // },
        {
          name: 'active',
          ignoreRowClick: true,
          button: true,
          maxWidth: '50px',
          cell: (row) => (
            <Components.SwitchComponent
              onChange={() => handleUpdateData(row, 'active')}
              data={row.IsActive}
            />
          )
        }
      ])
      setPending(false)
    }, 500)

    return () => clearTimeout(timeout)
  }, [])

  async function handleFetchData(page) {
    setPending(true)
    const URL = '/cupon_api/get_all_cupon'
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: auth?.accessToken
      },
      params: {
        page: page,
        size: perPage
      }
    }
    const response = await axios.get(URL, config)

    if (response.status === 200) {
      setData(_.sortBy(response?.data?.data?.rows || [], ['Id']))
      setTotalRows(response?.data?.data?.count)
      setPending(false)
    } else {
      Components.SweetAlert({
        html: `ไม่สามารถดำเนินการได้`,
        icon: 'error',
        showConfirmButton: false,
        timer: 1500
      })
    }
  }

  function handleRemoveData(row) {
    const URL = '/cupon_api/delete_cupon'
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: auth?.accessToken
      },
      params: { Id: row.Id }
    }

    Components.SweetAlert({
      html: `คุณยืนยันที่จะลบ ${row.CupOnKey} ใช่หรือไม่`,
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: 'ตกลง',
      cancelButtonText: 'ยกเลิก'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await axios.delete(URL, config)
        if (response.status === 200) {
          Components.SweetAlert({
            html: `รายการสำเร็จ`,
            icon: 'success',
            showConfirmButton: false,
            timer: 1500
          }).then(() => handleFetchData(1))
        } else {
          Components.SweetAlert({
            html: `ไม่สามารถดำเนินการได้`,
            icon: 'error',
            showConfirmButton: false,
            timer: 1500
          })
        }
      }
    })
  }

  async function handleUpdateData(row, type = '') {
    const status =
      type === 'active' ? { IsActive: !row.IsActive } : { IsUnlimited: !row.IsUnlimited }
    const response = await axios({
      method: 'PUT',
      url: '/cupon_api/update_cupon',
      headers: {
        'Content-Type': 'application/json',
        Authorization: auth?.accessToken
      },
      data: {
        Id: +row.Id,
        CupOnCount: row.CupOnCount,
        CupOnPrice: row.CupOnPrice,
        ...status
      }
    })

    if (response.status === 200) {
      Components.SweetAlert({
        html: `รายการสำเร็จ`,
        icon: 'success',
        showConfirmButton: false,
        timer: 1500
      }).then(() => handleFetchData(1))
    } else {
      Components.SweetAlert({
        html: `ไม่สามารถดำเนินการได้`,
        icon: 'error',
        showConfirmButton: false,
        timer: 1500
      })
    }
  }

  function handleModal(row) {
    _.isEmpty(row) ? setModalAction('') : setModalAction('update')
    setDataModal(row)
    return setOpenModal(true)
  }

  function handleModalClose() {
    setOpenModal(false)
    setRefreshStats(true)
    return handleFetchData(1)
  }

  const handlePageChange = (page) => {
    handleFetchData(page)
  }

  const handlePerRowsChange = async (newPerPage = 50, page = 1) => {
    setPending(true)
    const URL = '/cupon_api/get_all_cupon'
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: auth?.accessToken
      },
      params: {
        page: page,
        size: newPerPage
      }
    }
    const response = await axios.get(URL, config)

    setData(_.sortBy(response?.data?.data?.rows || [], ['Id']))
    setPerPage(newPerPage)
    setPending(false)
  }

  return (
    <Layouts.DashboardLayout
      msg={'Template Page'}
      refreshStats={refreshStats}
      setRefreshStats={setRefreshStats}
    >
      <div className="flex flex-1 flex-col space-y-2">
        <div className="flex flex-row space-x-3 items-center">
          <div className="w-full bg-primary text-white text-xl p-4 rounded-lg">
            คูปองที่มีการใช้อยู่ในปัจจุบัน
          </div>
          <div className="w-20 flex items-center justify-center cursor-pointer">
            <PlusCircleIcon
              onClick={() => handleModal('')}
              className="h-[60px] text-primary"
              aria-hidden="true"
            />
          </div>
        </div>
        <Components.TableComponent
          customStyles={customStyles}
          columns={columns}
          data={data}
          progressPending={pending}
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          paginationRowsPerPageOptions={[50, 100, 150, 200, 250, 300]}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
        />
      </div>

      <Components.CouponModalComponent
        open={openModal}
        data={dataModal}
        action={modalAction}
        closeModal={() => handleModalClose()}
      />
    </Layouts.DashboardLayout>
  )
}

export default CouponPage
