import { useState, useEffect, useContext } from 'react'
import dayjs from 'dayjs'
import { Components, Layouts, Images } from '../../constants'
import { Contexts } from '../../constants'
import axios from '../../services/axios'

const customStyles = {
  rows: {},
  headCells: {
    style: {
      backgroundColor: '#D9D9D9',
      color: '#2D3A30',
      fontSize: '16px',
      fontWeight: 800
    }
  },
  cells: {}
}

const initialColumns = [
  {
    name: 'No',
    selector: (row, index) => row.Id,
    sortable: true,
    ignoreRowClick: true,
    width: '100px'
  },
  {
    name: 'Studio',
    selector: (row) => row.EngineId,
    sortable: true,
    ignoreRowClick: true,
    width: '108px'
  },
  {
    name: 'วันเวลาใช้งาน',
    selector: (row) => dayjs(row.CreateDate).format('DD/MM/YYYY HH:mm'),
    sortable: true,
    ignoreRowClick: true,
    width: '160px'
  },
  {
    name: 'คูปองที่ใช้',
    selector: (row) => row.CupOn.CupOnKey,
    sortable: true,
    ignoreRowClick: true,
    width: '160px'
  },
  {
    name: 'รายละเอียดคูปอง',
    selector: (row) => row.CupOn.CupOnDescription,
    sortable: true,
    ignoreRowClick: true
  },
  {
    name: 'เทมเพลต',
    selector: (row) => row.ProductDetail,
    sortable: true,
    ignoreRowClick: true,
    width: '485px'
  },
  {
    name: 'จำนวนเงิน',
    selector: (row) => row.Total,
    sortable: true,
    ignoreRowClick: true,
    center: true,
    maxWidth: '130px'
  }
]

const UsageLogPage = () => {
  const { auth } = useContext(Contexts.AuthContext)
  const [columns, setColumns] = useState([])
  const [data, setData] = useState([])
  const [pending, setPending] = useState(true)
  const [totalRows, setTotalRows] = useState(0)
  const [perPage, setPerPage] = useState(10)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setColumns(initialColumns)
      handleFetchData(1)
      setPending(false)
    }, 500)

    return () => clearTimeout(timeout)
  }, [])

  async function handleFetchData(page) {
    setPending(true)
    const URL = '/payment_api/get_all_payment_order'
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: auth?.accessToken
      },
      params: {
        page: page,
        size: perPage,
        isActive: true
      }
    }
    const response = await axios.get(URL, config)

    setData(response?.data?.data?.rows || [])
    setTotalRows(response?.data?.data?.count)
    setPending(false)
  }

   const handlePageChange = (page) => {
    handleFetchData(page)
  }

  const handlePerRowsChange = async (newPerPage, page) => {
    setPending(true)
    const URL = '/payment_api/get_all_payment_order'
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: auth?.accessToken
      },
      params: {
        page: page,
        size: newPerPage,
        isActive: true
      }
    }
    const response = await axios.get(URL, config)

    setData(response?.data?.data?.rows || [])
    setPerPage(newPerPage)
    setPending(false)
  }

  return (
    <Layouts.DashboardLayout msg={'Template Page'}>
      <div className="flex flex-1 flex-col space-y-2">
        <div className="flex flex-row space-x-3 items-center">
          <div className="w-full bg-primary text-white text-xl p-4 rounded-lg">
            ข้อมูลการใช้งานเครื่องถ่ายรูปของผู้ใช้
          </div>
        </div>
        <Components.TableComponent
          customStyles={customStyles}
          columns={columns}
          data={data}
          progressPending={pending}
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
        />
      </div>
    </Layouts.DashboardLayout>
  )
}

export default UsageLogPage
