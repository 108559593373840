import { Fragment, useState, useEffect, useContext, useMemo } from 'react'
import Cookies from 'js-cookie'
import _ from 'lodash'
import axios from '../../services/axios'
import { Components, Contexts } from '../../constants'

const CouponModalComponent = (props) => {
  const { auth } = useContext(Contexts.AuthContext)

  const [data, setData] = useState({
    LoopTotal: 1
  })
  const options = [
    { label: 1, value: 1 },
    { label: 2, value: 2 },
    { label: 3, value: 3 },
    { label: 4, value: 4 },
    { label: 5, value: 5 },
    { label: 6, value: 6 },
    { label: 7, value: 7 },
    { label: 8, value: 8 },
    { label: 9, value: 9 },
    { label: 10, value: 10 }
  ]

  useEffect(() => {
    if (props.action === 'update') {
      setData(props?.data)
    }
  }, [props.data])

  async function handleReset() {
    setData('')
    await props.closeModal(false)
  }

  function handleSelectData(data) {
    setSelectData(data?.value?.name || '')
    setSelectDataID(data?.value || '')
  }

  function handleCreate() {
    Components.SweetAlert({
      html: `คุณยืนยันบันทึกคูปองนี้ใช่หรือไม่`,
      icon: 'question',
      showConfirmButton: true,
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก',
      allowOutsideClick: false,
      allowEscapeKey: false
    }).then((result) => {
      if (result.isConfirmed) {
        axios({
          method: 'POST',
          url: _.isEqual(_.toNumber(data?.LoopTotal), 1)
            ? 'cupon_api/create_cupon'
            : 'cupon_api/create_cupon_with_loop',
          headers: {
            'Content-Type': 'application/json',
            Authorization: auth?.accessToken
          },
          data: _.isEqual(_.toNumber(data?.LoopTotal), 1)
            ? {
                CupOnCount: data?.CupOnCount || 15,
                CupOnPrice: data?.CupOnPrice || 150,
                CupOnDescription: data?.CupOnDescription || '',
                IsActive: data?.IsActive || true,
                IsUnlimited: false
              }
            : {
                CupOnCount: data?.CupOnCount || 15,
                CupOnPrice: data?.CupOnPrice || 150,
                CupOnDescription: data?.CupOnDescription || '',
                LoopTotal: data?.LoopTotal || 1,
                IsActive: data?.IsActive || true,
                IsUnlimited: false
              }
        })
          .then((response) => {
            if (response.status === 200) {
              Components.SweetAlert({
                title: 'บันทึกข้อมูลเรียบร้อยแล้ว',
                icon: 'success',
                showConfirmButton: false,
                timer: 1750,
                allowOutsideClick: false,
                allowEscapeKey: false
              }).then(() => handleReset())
            }
          })
          .catch((error) => {
            console.log(error)
            Components.SweetAlert({
              title:
                error.response?.data?.message || 'ไม่สามารถส่งข้อมูลของคุณได้ กรุณาลองใหม่อีกครั้ง',
              icon: 'error',
              iconColor: '#DC2626',
              showConfirmButton: true,
              confirmButtonText: 'ยืนยัน',
              allowOutsideClick: false,
              allowEscapeKey: false
            })
          })
      }
    })
  }

  async function handleUpdate() {
    const response = await axios({
      method: 'PUT',
      url: '/cupon_api/update_cupon',
      headers: {
        'Content-Type': 'application/json',
        Authorization: auth?.accessToken
      },
      data: {
        Id: +data.Id,
        CupOnCount: data.CupOnCount,
        CupOnPrice: data.CupOnPrice,
        CupOnDescription: data.CupOnDescription
      }
    })

    if (response.status === 200) {
      Components.SweetAlert({
        title: 'รายการสำเร็จ',
        icon: 'success',
        showConfirmButton: false,
        timer: 1750,
        allowOutsideClick: false,
        allowEscapeKey: false
      }).then(() => handleReset())
    } else {
      Components.SweetAlert({
        html: `ไม่สามารถดำเนินการได้`,
        icon: 'error',
        showConfirmButton: false,
        timer: 1500
      })
    }
  }

  async function handleOnBack() {
    return handleReset()
  }

  function handleSubmit({ action }) {
    switch (action) {
      case 'update':
        return handleUpdate()
      default:
        return handleCreate()
    }
  }

  return (
    <Components.ModalComponent
      open={props?.open}
      close={props?.closeModal}
      title={props.action === 'update' ? 'แก้ไขคูปอง' : 'เพิ่มคูปอง'}
      buttonCloseText={'ปิด'}
      buttonClose={false}
      size={props?.action === 'update' ? 'max-w-xl h-[51vh]' : 'max-w-xl h-[51vh]'}
    >
      <div className="flex flex-col text-left space-y-3">
        <div className="flex flex-1 flex-col justify-center">
          {props.action === 'update' ? (
            <div className="col-auto my-2 w-full">
              <label
                htmlFor="route_simulation_origin"
                className="block text-lg font-db font-bold text-dark-gray pb-2"
              >
                รหัสคูปอง
              </label>
              <div className="flex w-full flex-col">
                <input
                  id="CupOnKey"
                  name="CupOnKey"
                  type="text"
                  placeholder="โปรดระบุ"
                  autoComplete="off"
                  value={data?.CupOnKey || ''}
                  onChange={(e) => setData({ ...data, CupOnKey: e.target.value })}
                  disabled={props.action === 'update'}
                  className="appearance-none block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:primary focus:border-primary sm:text-sm disabled:cursor-not-allowed"
                />
              </div>
            </div>
          ) : (
            <></>
          )}

          <div className="col-auto my-2 w-full">
            <label
              htmlFor="route_simulation_origin"
              className="block text-lg font-db font-bold text-dark-gray pb-2"
            >
              จำนวนคูปองที่ใช้ได้
            </label>
            <div className="flex w-full flex-col">
              <input
                id="CupOnCount"
                name="CupOnCount"
                type="number"
                placeholder="โปรดระบุ"
                autoComplete="off"
                value={data?.CupOnCount || ''}
                onChange={(e) => setData({ ...data, CupOnCount: e.target.value })}
                className="appearance-none block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:primary focus:border-primary sm:text-sm disabled:cursor-not-allowed"
              />
            </div>
          </div>

          <div className="col-auto my-2 w-full">
            <label
              htmlFor="route_simulation_origin"
              className="block text-lg font-db font-bold text-dark-gray pb-2"
            >
              รายละเอียด
            </label>
            <div className="flex w-full flex-col">
              <input
                id="CupOnCount"
                name="CupOnCount"
                type="text"
                placeholder="โปรดระบุ"
                autoComplete="off"
                value={data?.CupOnDescription || ''}
                onChange={(e) => setData({ ...data, CupOnDescription: e.target.value })}
                className="appearance-none block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:primary focus:border-primary sm:text-sm disabled:cursor-not-allowed"
              />
            </div>
          </div>

          <div className="col-auto my-2 w-full">
            <label
              htmlFor="route_simulation_origin"
              className="block text-lg font-db font-bold text-dark-gray pb-2"
            >
              ราคา
            </label>
            <div className="flex w-full flex-col">
              <input
                id="CupOnPrice"
                name="CupOnPrice"
                type="number"
                placeholder="โปรดระบุ"
                autoComplete="off"
                value={data?.CupOnPrice || ''}
                onChange={(e) => setData({ ...data, CupOnPrice: e.target.value })}
                className="appearance-none block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:primary focus:border-primary sm:text-sm disabled:cursor-not-allowed"
              />
            </div>
          </div>

          {props.action === 'update' ? (
            <></>
          ) : (
            <div className="col-auto my-2 w-full">
              <label
                htmlFor="route_simulation_origin"
                className="block text-lg font-db font-bold text-dark-gray pb-2"
              >
                จำนวนครั้งที่สร้าง
              </label>
              <div className="flex w-full flex-col">
                <select
                  id="LoopTotal"
                  name="LoopTotal"
                  onChange={(e) => setData({ ...data, LoopTotal: e.target.value })}
                  className="py-2 px-4 block w-full bg-white border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:primary focus:border-primary sm:text-sm disabled:cursor-not-allowed"
                >
                  {!_.isEmpty(options) &&
                    _.map(options, (o, i) => (
                      <option key={i} value={o.value}>
                        {o.label}
                      </option>
                    ))}
                </select>
              </div>
            </div>
          )}
        </div>
        {/* Footer */}
        <div className="flex flex-1 justify-center">
          <div className="flex items-center justify-center mx-5 lg:mx-auto grid-cols-2 gap-6 mb-3 lg:mb-0">
            <div className="col-auto">
              <button
                type="submit"
                onClick={() => handleOnBack()}
                className="w-full flex justify-center py-2 px-4 border border-primary rounded-md shadow-sm text-sm font-medium text-primary bg-white hover:bg-opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:primary focus:ring-primary"
              >
                ยกเลิก
              </button>
            </div>
            <div className="col-auto">
              <button
                type="submit"
                onClick={() => handleSubmit({ action: props.action })}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:primary focus:ring-primary"
              >
                {props.action === 'update' ? 'แก้ไข' : 'บันทึก'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Components.ModalComponent>
  )
}

export default CouponModalComponent
