import { useContext } from 'react'
import { Contexts } from '../constants'

const useRefreshToken = () => {
  const { setAuth } = useContext(Contexts.AuthContext)

  const refresh = async () => {
    if (localStorage.getItem('token')) {
      setAuth((prev) => {
        return {
          ...prev,
          roles: `${localStorage.getItem('roles')}` || '',
          accessToken: `Bearer ${localStorage.getItem('token')}` || '',
          fullname: localStorage.getItem('fullname') || ''
        }
      })
    }
    return localStorage.getItem('token')
  }
  return refresh
}

export default useRefreshToken
