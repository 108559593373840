import { useRef, useState, useEffect, useContext } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { UserIcon, LockClosedIcon } from '@heroicons/react/24/outline'

import { Layouts, Images, Contexts } from '../constants'

import useAuth from '../hooks/useAuth'
import useInput from '../hooks/useInput'
import useToggle from '../hooks/useToggle'

import axios from '../services/axios'
const LOGIN_URL = '/user_api/login'

const LoginPage = () => {
  // const { setAuth } = useAuth()

  const navigate = useNavigate()
  const location = useLocation()
  const authContext = useContext(Contexts.AuthContext)
  const from = location.state?.from?.pathname || '/container/dashboard'

  const userRef = useRef()
  const errRef = useRef()

  const [user, resetUser, userAttribs] = useInput('user', '')
  const [pwd, setPwd] = useState('')
  const [errMsg, setErrMsg] = useState('')
  const [check, toggleCheck] = useToggle('persist', false)

  useEffect(() => {
    userRef.current.focus()
  }, [])

  useEffect(() => {
    setErrMsg('')
  }, [user, pwd])

  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      const response = await axios.post(
        LOGIN_URL,
        JSON.stringify({ UserName: user, PassWord: pwd }),
        {
          headers: { 'Content-Type': 'application/json' },
          // withCredentials: true
        }
      )
      const accessToken = `Bearer ${response.data?.data?.token}`
      const roles = response.data?.data?.UserTypes?.Id
      const fullname = `${response.data?.data?.FirstNameEn} ${response.data?.data?.LastNameEn}`
      authContext.setAuth({ roles, accessToken, fullname })
      localStorage.setItem('token', response.data?.data?.token)
      localStorage.setItem('fullname', fullname)
      localStorage.setItem('roles', roles)
      resetUser()
      setPwd('')
      navigate(from, { replace: true })
    } catch (err) {
      console.log(err)
      if (!err?.response) {
        setErrMsg('No Server Response')
      } else if (err.response?.status === 400) {
        setErrMsg('Missing Username or Password')
      } else if (err.response?.status === 401) {
        setErrMsg('Unauthorized')
      } else {
        setErrMsg('Login Failed')
      }
      errRef.current.focus()
    }
  }

  return (
    <Layouts.SignInLayout>
      <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 xl:flex-none lg:px-12 xl:px-12 rounded-xl bg-secondary">
        <div className="mx-auto w-full max-w-sm xl:w-96">
          <p ref={errRef} className={errMsg ? 'errmsg' : 'offscreen'} aria-live="assertive">
            {errMsg}
          </p>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="font-bold text-lg">Sign in</div>
            <div className="mt-1 relative rounded-md shadow-sm">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <UserIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </div>
              <input
                id="username"
                name="username"
                type="text"
                placeholder="Username"
                ref={userRef}
                autoComplete="off"
                {...userAttribs}
                // required
                className="appearance-none block w-full pl-10 px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:primary focus:border-primary sm:text-sm"
              />
            </div>

            <div className="mt-1 relative rounded-md shadow-sm">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <LockClosedIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </div>
              <input
                id="password"
                name="password"
                type="password"
                placeholder="Password"
                onChange={(e) => setPwd(e.target.value)}
                value={pwd}
                // required
                className="appearance-none block w-full pl-10 px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:primary focus:border-primary sm:text-sm"
              />
            </div>

            <div>
              <button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:primary focus:ring-primary"
              >
                Sign in
              </button>
            </div>
          </form>
        </div>
      </div>

      <div className="hidden lg:flex flex-1 xl:items-center xl:justify-center">
        <img src={Images.LOGO_TEXT} alt={`Lacol Studio`} />
      </div>

      <div className="hidden md:block absolute lg:w-[200px] xl:w-[254px] left-0 lg:left-[1%] xl:left-[11.5%] bottom-0 xl:bottom-[2%]">
        <div className="relative">
          <img className="bottom-0" src={Images.FILM} alt={`Lacol Studio - Photo Films`} />
          <div className="absolute w-full left-[69%] bottom-10">
            <img src={Images.ROLLER} alt={`Lacol Studio - Roller Skates`} />
          </div>
        </div>
      </div>
    </Layouts.SignInLayout>
  )
}

export default LoginPage
