import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const swalWithTailwindButton = withReactContent(
  Swal.mixin({
    customClass: {
      actions: 'flex flex-row items-center justify-center',
      confirmButton:
        'w-full flex-1 justify-center py-2 px-4 mx-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:primary focus:ring-primary',
      cancelButton:
        'w-full flex-1 justify-center py-2 px-4 mx-2 border border-primary rounded-md shadow-sm text-sm font-medium text-primary bg-white hover:bg-opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:primary focus:ring-primary'
    },
    buttonsStyling: false
  })
)

export const SweetAlert = ({
  title,
  text,
  icon,
  showCancelButton,
  showConfirmButton,
  cancelButtonColor,
  confirmButtonColor,
  confirmButtonText,
  cancelButtonText,
  reverseButtons,
  timer,
  ...props
}) => {
  return swalWithTailwindButton.fire({
    title: title || false,
    text: text,
    icon: icon,
    showCancelButton: showCancelButton || false,
    showConfirmButton: showConfirmButton || false,
    cancelButtonColor: cancelButtonColor || '#ef4444',
    confirmButtonColor: confirmButtonColor || '#2D3A30',
    confirmButtonText: confirmButtonText || 'ยืนยัน',
    cancelButtonText: cancelButtonText || 'ยกเลิก',
    reverseButtons: reverseButtons || true,
    timer: timer === true ? 1500 : timer ? timer : false,
    ...props
  })
}
