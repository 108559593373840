import { createContext, useContext, useState } from 'react'
import { node } from 'prop-types'

const ContainerContext = createContext({})

export function useContainerContext() {
  return useContext(ContainerContext)
}

const ContainerProvider = ({ children }) => {
  const [data, setData] = useState(null)

  function handleUpdateData(value) {
    setData((prevData) => ({
      ...prevData,
      value
    }))
  }

  const store = {
    data,
    action: {
      handleUpdateData
    }
  }

  return <ContainerContext.Provider value={store}>{children}</ContainerContext.Provider>
}

ContainerProvider.propTypes = {
  children: node.isRequired
}

export default ContainerProvider
