import { createContext, useContext, useState } from 'react'
import { node } from 'prop-types'

export const AuthContext = createContext({
  auth: null,
  setAuth: () => {}
})

const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(null)

  return <AuthContext.Provider value={{ auth, setAuth }}>
    {children}
    </AuthContext.Provider>
}

AuthProvider.propTypes = {
  children: node.isRequired
}

export default AuthProvider
