import { useContext, useEffect } from 'react'
import { useLocation, Navigate, Outlet } from 'react-router-dom'
import _ from 'lodash'
import jwt_decode from 'jwt-decode'
import dayjs from 'dayjs'
import useAuth from '../hooks/useAuth'
import { Contexts } from '../constants'

const RequireAuthLayouts = ({ allowedRoles }) => {
  const { auth } = useContext(Contexts.AuthContext)
  const location = useLocation()
  const jwtPayload = auth?.accessToken ? jwt_decode(auth?.accessToken) : undefined
  const isExpired = dayjs() >= jwtPayload?.exp * 1000

  return allowedRoles?.includes(auth?.roles) ? (
    <Outlet />
  ) : auth?.accessToken ? (
    isExpired ? (
      //changed from user to accessToken to persist login after refresh
      <Navigate to="/unauthorized" state={{ from: location }} replace />
    ) : (
      // auto login after refresh
      <Outlet />
    )
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  )
}

export default RequireAuthLayouts
