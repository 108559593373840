import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'

import LoadingComponent from '../components/Loading'

const RouteLayoutLayouts = () => {
  return (
    <Suspense fallback={<LoadingComponent />}>
      <Outlet />
    </Suspense>
  )
}

export default RouteLayoutLayouts
