import { useContext } from 'react'
import { Contexts } from '../constants'

const useLogout = () => {
  const { setAuth } = useContext(Contexts.AuthContext)

  const logout = async () => {
    setAuth({})
    try {
      // const response = await axios('/logout', {
      //   withCredentials: true
      // })
      localStorage.clear()
      sessionStorage.clear()
    } catch (err) {
      console.error(err)
    }
  }

  return logout
}

export default useLogout
