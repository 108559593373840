import React from 'react'
import PropTypes from 'prop-types'
import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'

const ItemModalComponent = props => {
    return (
        <Transition.Root show={props.open} as={Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={props.close}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 "
                        enterTo="opacity-100 translate-y-0 "
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-4"
                    >
                        <div className={`${props.size} inline-block bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-auto shadow-xl transform transition-all my-8 align-middle w-full sm:p-6`}>
                            {
                                props.dismissButton &&
                                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                                    <button
                                        type="button"
                                        className="bg-white rounded-md text-gray-400 hover:text-gray-500"
                                        onClick={() => props.close(false)}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>
                            }
                            <div>
                                {props.icon}
                                <div className="mt-3 text-center sm:mt-5">
                                    <Dialog.Title as="h3" className="!text-[22px] !leading-6 !font-bold !text-blue-dark">
                                        {props.title}
                                    </Dialog.Title>
                                    <div className="mt-2">
                                        {props.children}
                                    </div>
                                </div>
                            </div>
                            {
                                props.buttonClose &&
                                <div className="mx-auto flex items-center justify-center mt-5 sm:mt-6">
                                    <button
                                        type="button"
                                        className="inline-flex justify-center w-1/4 rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary text-base font-medium text-white hover:bg-opacity-90 sm:text-sm"
                                        onClick={() => props.close(false)}
                                    >
                                        {props.buttonCloseText || 'Close'}
                                    </button>
                                </div>
                            }
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

ItemModalComponent.defaultProps = {
    size: 'max-w-4xl',
    dismissButton: false,
    icon: <></>,
    title: '',
    buttonClose: true,
    buttonCloseText: 'ปิด',
    open: false,
    close: () => { }
}

ItemModalComponent.propTypes = {
    size: PropTypes.string,
    dismissButton: PropTypes.bool,
    icon: PropTypes.element,
    title: PropTypes.string,
    buttonClose: PropTypes.bool,
    buttonCloseText: PropTypes.string,
    open: PropTypes.bool,
    close: PropTypes.func
}

export default ItemModalComponent