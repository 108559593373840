import { useState, useEffect, useContext } from 'react'
import { Contexts, Layouts } from '../../constants'
import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts'
import axios from '../../services/axios'

const DashboardPage = () => {
  const { auth } = useContext(Contexts.AuthContext)
  const [data, setData] = useState([])
  const [current, setCurrent] = useState(0)
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear())

  useEffect(() => {
    handleFetchData()

    return () => {
      setData([])
    }
  }, [current, currentYear])

  async function handleFetchData() {
    const URL = '/payment_api/get_pay_order_year'
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: auth?.accessToken
      },
      params: {
        year: currentYear
      }
    }
    const response = await axios.get(URL, config)

    setData(response?.data?.data || [])
  }

  function handleSelectChart(key) {
    switch (+key) {
      case 1:
        return (
          <BarChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5
            }}
          >
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#3b82f6" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#3b82f6" stopOpacity={0} />
              </linearGradient>
            </defs>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="MonthName" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar
              dataKey="Count"
              stroke="#3b82f6"
              strokeWidth={2}
              fill="url(#colorUv)"
              fillOpacity={1}
              name="จำนวนที่ถ่าย (ครั้ง)"
            />
          </BarChart>
        )

      default:
        return (
          <AreaChart
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5
            }}
          >
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#3b82f6" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#3b82f6" stopOpacity={0} />
              </linearGradient>
            </defs>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="MonthName" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Area
              type="monotone"
              dataKey="Total"
              stroke="#3b82f6"
              strokeWidth={2}
              fill="url(#colorUv)"
              fillOpacity={1}
              name="ยอดขาย (บาท)"
            />
          </AreaChart>
        )
    }
  }

  function handleDropListYear() {
    const year = new Date().getFullYear()
    return Array.from(new Array(10), (v, i) => (
      <option key={i} value={year - i}>
        {year - i}
      </option>
    ))
  }

  return (
    <Layouts.DashboardLayout msg={'Dashboard Page'}>
      <div className="flex flex-col w-full h-[73vh] space-y-3">
        <div className="flex justify-end px-6">
          <div className="flex flex-row space-x-3">
            <div className="row-auto">
              <select
                onChange={(v) => setCurrentYear(v.target.value)}
                className="border py-2 px-4 rounded-md w-[200px]"
              >
                {handleDropListYear()}
              </select>
            </div>

            <div className="row-auto">
              <select
                onChange={(v) => setCurrent(v.target.value)}
                className="border py-2 px-4 rounded-md w-[200px]"
              >
                <option value="0">ยอดขาย</option>
                <option value="1">จำนวนที่ถ่าย</option>
              </select>
            </div>
          </div>
        </div>
        <ResponsiveContainer>{handleSelectChart(current)}</ResponsiveContainer>
      </div>
    </Layouts.DashboardLayout>
  )
}

export default DashboardPage
